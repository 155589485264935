import React from "react"
import styled, { css } from "styled-components"

const fontCss = css`
  font-size: 40px;
  font-weight: 700;

  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 26px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${fontCss}
`

const Title = styled.h1`
  background: -webkit-linear-gradient(
    124.24deg,
    #f33191 0%,
    #4870c4 49.48%,
    #30d8fb 150%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  ${fontCss}
`

const Emoji = styled.div`
  margin-right: 16px;
  ${fontCss}
`

interface BlogTitleProps {
  title: string
  emoji?: string
  className?: string
}

export const BlogTitle: React.FC<BlogTitleProps> = ({
  emoji,
  title,
  className,
}) => (
  <Wrapper className={className}>
    {emoji && <Emoji>{emoji}</Emoji>}
    <Title>{title}</Title>
  </Wrapper>
)
