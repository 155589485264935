import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Anchor } from "../../atoms/Anchor/Anchor"
import { MyAvatarImage } from "../../atoms/MyAvatarImage/MyAvatarImage"

const LinearGradientBox = styled.div`
  width: 100vw;
  background: #303030;
`

const StyledAnchor = styled(Anchor)`
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 0 4px white inset;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 998px;
  margin: auto;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  box-sizing: border-box;

  @media only screen and (max-width: 998px) {
    width: 100%;
    padding: 0 16px;
  }
`

const LeftBox = styled.div`
  display: flex;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  box-sizing: border-box;
`

const RightBox = styled.div`
  display: flex;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  box-sizing: border-box;

  @media only screen and (max-width: 500px) {
    .twitter-icon {
      display: none;
    }
  }

  @media only screen and (max-width: 400px) {
    .blog-link {
      display: none;
    }
  }
`

const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`

const HeaderAnchor = styled(Anchor)`
  color: white !important;
  text-decoration: none;
  margin-right: 32px;

  :hover {
    color: white;
    text-decoration: underline;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    margin-right: 0;
  }
`

export const TopBar = () => {
  return (
    <LinearGradientBox>
      <Content>
        <LeftBox>
          <Title>
            <StyledAnchor href="/">
              <MyAvatarImage style={{ width: "64px", height: "64px" }} />
            </StyledAnchor>
            &nbsp;&nbsp; Luca
          </Title>
        </LeftBox>
        <RightBox>
          <HeaderAnchor href="/">About me</HeaderAnchor>
          <HeaderAnchor href="/blog" className="blog-link">
            Blog
          </HeaderAnchor>
          <HeaderAnchor
            href="https://www.twitter.com/ikoichi"
            target="_blank"
            className="twitter-icon"
          >
            <StaticImage
              src="../../../images/twitter.svg"
              alt="twitter"
              width={32}
              quality={100}
            />
          </HeaderAnchor>
        </RightBox>
      </Content>
    </LinearGradientBox>
  )
}
