import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export const MyAvatarImage = ({ style }) => {
  const avatarData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={avatarData.file.childImageSharp.fluid}
      alt="Luca Restagno Picture"
      style={{
        position: "relative",
        display: "flex",
        ...style,
      }}
    />
  )
}
