import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { BlogTitle } from "../components/atoms/BlogTitle/BlogTitle"
import { Flex } from "../components/atoms/Flex/Flex"
import { Gap } from "../components/atoms/Gap/Gap"
import { Text } from "../components/atoms/Typography/Typography"
import Layout from "../components/layout"
import { Footer } from "../components/molecules/Footer/Footer"
import { NewsletterForm } from "../components/molecules/NewsletterForm/NewsletterForm"
import { TopBar } from "../components/molecules/TopBar/TopBar"

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin: auto;
  margin-bottom: 0;
  padding: 0;

  @media only screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`

const Section = styled.div`
  display: flex;
  padding: 5vh 0;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 2vh 0;
    width: 80%;
  }
`

const StyledBlogTitle = styled(BlogTitle)`
  margin-bottom: 32px;
`

const CenteredSection = styled(Section)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledNewsletterForm = styled(NewsletterForm)`
  width: 480px;

  .subscribe-container {
    width: 480px;
  }

  @media only screen and (max-width: 768px) {
    width: 80%;
    .subscribe-container {
      width: 80%;
    }
  }
`

const Newsletter = () => {
  return (
    <Layout>
      <Helmet title="Newsletter">
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Luca Restagno</title>
        <link rel="canonical" href="https://www.lucarestagno.com/" />
        <meta
          name="description"
          content="Luca Restagno Newsletter — Posts about web development, SaaS products and more"
        />
      </Helmet>
      <Container>
        <TopBar />
        <Content>
          <Section>
            <Flex flexDirection="column">
              <Gap height={24} />
              <StyledBlogTitle emoji="✉️" title="Newsletter" />
              <Gap height={24} />
              <Text style={{ textAlign: "center" }}>
                I write about web development, SaaS products and more.
              </Text>
            </Flex>
          </Section>
        </Content>
        <CenteredSection>
          <StyledNewsletterForm
            title="Dont' miss an issue"
            subtitle="800+ developers already joined"
          />
          <Gap height={32} />
        </CenteredSection>
        <Footer />
      </Container>
    </Layout>
  )
}

export default Newsletter
