import React, { ReactNode } from "react"
import styled from "styled-components"
import { ReactMailchimpEmailSignupForm } from "react-mailchimp-email-signup-form"
import "react-mailchimp-email-signup-form/dist/esm/index.css"

const Wrapper = styled.div`
  background: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .subscribe-container {
    padding: 24px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;

    @media only screen and (max-width: 768px) {
      border-radius: 4px;
    }
  }

  #first-email-signup-formmc_embed_signup_scroll > div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .subscribe-container__title {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 32px;
    color: #424242;
    font-weight: 300;
    text-align: center;
  }

  .subscribe-container__subtitle {
    color: #797979;
  }

  .subscribe-container__email {
    width: 80%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    margin-bottom: 16px;
    box-sizing: border-box;

    transition: all 0.15s linear;
  }

  .subscribe-container__email:focus {
    outline: none;
    border: 1px solid #d6d6d6;
    box-shadow: 0 0 8px #dadada;
  }

  button {
    display: flex;
    border-radius: 8px;
    border: none;
    outline: none;
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
    width: 80%;
    cursor: pointer;

    font-weight: 700;
    padding: 16px 24px;

    transition: all 0.15s ease-in-out;
    background: black;

    :hover {
      background: #3b3b3b;
    }

    :active {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, #000000 100%);
    }
  }
`

interface NewsletterFormProps {
  className?: string
  title?: ReactNode | string
  subtitle?: ReactNode | string
}

const defaultTitle = (
  <>
    Did you enjoy this content?
    <br />
    ✉️ Subscribe to my newsletter
  </>
)

const defaultSubtitle =
  "You won't receive any spam. Only up to 2 emails per month."

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
  title = defaultTitle,
  subtitle = defaultSubtitle,
  className,
}) => (
  <Wrapper className={className}>
    <ReactMailchimpEmailSignupForm
      elementId="first-email-signup-form"
      url="https://lucarestagno.us6.list-manage.com/subscribe/post?u=65f1607902279f603840ef132&amp;id=0af6a141b5"
      title={title}
      subtitle={subtitle}
    />
  </Wrapper>
)
