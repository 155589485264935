import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export const Anchor = styled(OutboundLink)`
  color: #2799bd;

  :active {
    color: #2799bd;
  }

  :visited {
    color: #2799bd;
  }

  :hover {
    color: #1c7692;
  }
`
